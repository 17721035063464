document.addEventListener('DOMContentLoaded', function (event) {
  // console.log('DOM fully loaded and parsed')
  gsap.registerPlugin(ScrollTrigger)

  ScrollTrigger.normalizeScroll(true)

  // Odi na pocetak Contneta

  document.querySelectorAll('.skrolaj').forEach((btn, index) => {
    btn.addEventListener('click', (ev) => {
      ev.preventDefault()
      gsap.to(window, { duration: 1, scrollTo: { y: '.content', offsetY: 70 } })
    })
  })

  // document.querySelector('.skrolaj')(btn, index) => {
  //   btn.addEventListener('click', (ev) => {
  //     ev.preventDefault()
  //     gsap.to(window, { duration: 1, scrollTo: { y: '.content', offsetY: 70 } })
  //   })
  // })

  // Main Menu

  const menuAnchor = document.querySelector('.menubutton')
  const closeMenu = document.querySelector('.menuclose')
  const menuWrapper = document.querySelector('.mainMenu')

  const tl = gsap.timeline()

  menuAnchor.addEventListener('click', function () {
    document.body.classList.add('menuout')
    gsap.to(window, { duration: 0.1, scrollTo: 10 })
    if (!menuAnchor.classList.contains('is-active')) {
      this.classList.add('is-active')

      // tl.to(window, {
      //   scrollTo: 0,
      //   duration: 0.5,
      // })
    }
  })
  closeMenu.addEventListener('click', function () {
    document.body.classList.remove('menuout')

    if (menuAnchor.classList.contains('is-active')) {
      menuAnchor.classList.remove('is-active')

      // gsap.to(menuWrapper, {
      //   y: '-100%',
      //   duration: 0.5,
      // })
    }
  })

  gsap.from(
    '.logo',
    {
      duration: 1,
      scale: 2,
      opacity: 1,
      x: '50vw',
      xPercent: -50,
      yPercent: 20,
      filter: 'grayscale(1) brightness(10)',
    },
    1
  )

  gsap.from(
    '.heroFront',
    {
      duration: 0.5,
      scale: 2,
      opacity: 0,
      yPercent: 150,
    },
    2
  )

  // gsap.from(
  //   '.barMenu',
  //   {
  //     duration: 0.5,
  //     scale: 1.5,
  //     opacity: 0,
  //   },
  //   2
  // )

  gsap.from(
    '.kalendar-natjecanja',
    {
      duration: 0.5,
      opacity: 0,
    },
    3
  )

  const showAnim = gsap
    .from('header', {
      yPercent: -100,
      paused: true,
      duration: 0.2,
    })
    .progress(1)

  ScrollTrigger.create({
    start: '350 top',
    end: 99999,
    // markers: true,
    onUpdate: (self) => {
      self.direction === -1 ? showAnim.play() : showAnim.reverse()
    },
  })

  // var tlheader = gsap.timeline()
  // tlheader.to('header', {
  //   scrollTrigger: {
  //     trigger: '.body',
  //     // markers: true,
  //     start: 'top top',
  //     duration: 0.5,
  //     // end: '+=250px',
  //     // pin: '.container',
  //     // scrub: 1,
  //     toggleActions: 'play complete reverse ',
  //     // once: true,
  //   },
  //   backgroundColor: 'rgba(255,255,255,0.8)',
  //   backdropFilter: 'blur(3px)',
  //   // opacity: 1,
  // })

  gsap.from(
    'header',
    {
      duration: 0.5,
      backgroundColor: 'rgba(255,255,255,0)',
      backdropFilter: 'blur(0)',
    },
    2
  )

  gsap.from(
    '.barMenu',
    {
      duration: 0.5,
      opacity: 0,
      scale: 1.5,
    },
    2
  )

  // gsap.from('.banneri-naslovnica', {
  //   scrollTrigger: {
  //     trigger: 'body',
  //     // markers: true,
  //     start: 'top top',
  //     duration: 0.7,
  //     // pin: '.container',
  //     // scrub: 1,
  //     toggleActions: 'play complete complete none',
  //   },
  //   opacity: 0,
  // })

  gsap.to(
    '.headerOverlay',
    {
      duration: 0.5,
      backgroundColor: 'rgba(5,5,5,0.2)',
      backdropFilter: 'blur(20px)',
    },
    1.5
  )
  //   .to(
  //     '.headerOverlay',
  //     {
  //       scrollTrigger: {
  //         scroller: 'body',
  //         trigger: '.headerOverlay',
  //         markers: true,
  //         start: 'top+=10 top',
  //         end: '+=200',
  //         // pin: '.container',
  //         scrub: 1,
  //         pinSpacing: false,
  //         // toggleActions: 'play reverse complete reverse',
  //       },
  //       backgroundColor: 'rgba(5,5,5,0)',
  //       backdropFilter: 'blur(0px)',
  //     },
  //     '>'
  //   )

  var bgReveal = gsap.timeline({
    scrollTrigger: {
      trigger: '.headerHero',
      start: 'top+=150px top',
      // markers: true,
      pinSpacing: false,
      toggleActions: 'play complete none reverse',
    },
  })
  bgReveal.to('.headerOverlay', {
    duration: 1,
    // ease: 'Power2.easeOut',
    backgroundColor: 'rgba(5,5,5,0)',
    backdropFilter: 'blur(0px)',
  })

  var bgHide = gsap.timeline({
    scrollTrigger: {
      trigger: '.content',
      start: 'top center-=150px',
      // markers: true,
      pinSpacing: false,
      toggleActions: 'play complete none reverse',
    },
  })
  bgHide.to('.headerOverlay', {
    duration: 1,
    ease: 'Power2.easeOut',
    backgroundColor: 'rgba(5,5,5,0.2)',
    backdropFilter: 'blur(20px)',
  })

  // .to(
  //   '.headerOverlay',
  //   {
  //     scrollTrigger: {
  //       scroller: 'body',
  //       trigger: '.headerOverlay',
  //       markers: true,
  //       start: 'top+=10 top',
  //       end: '+=200',
  //       // pin: '.container',
  //       scrub: 1,
  //       pinSpacing: false,
  //       // toggleActions: 'play reverse complete reverse',
  //     },
  //     backgroundColor: 'rgba(5,5,5,0)',
  //     backdropFilter: 'blur(0px)',
  //   },
  //   '>'
  // )

  gsap.from('.frontline', {
    scrollTrigger: {
      trigger: 'body',
      start: 'top top',
      end: '+=400',
      pin: '.frontline',
      pinType: 'fixed', // transform fixed
      pinSpacing: false,
      // toggleActions: 'play complete complete reverse',
    },
  })

  gsap.to('.user', {
    scrollTrigger: {
      // trigger: 'body',
      trigger: '.headerHero',
      start: 'top+=170px top',
      // start: 'top top',
      // end: '+=400',
      duration: 1,
      toggleActions: 'play complete complete reverse',
    },
    opacity: 0,
  })

  gsap.to('.skrolaj', {
    scrollTrigger: {
      trigger: 'body',
      start: 'top+=300 top',
      duration: 0.7,
      // pin: '.skrolaj',
      pinType: 'fixed', // transform fixed
      toggleActions: 'play complete complete reverse',
    },
    opacity: 0,
  })

  // gsap
  //   .timeline({
  //     scrollTrigger: {
  //       trigger: '.kalendar-natjecanja',
  //       start: 'top top',
  //       end: 'bottom top',
  //       scrub: true,
  //       pin: true,
  //     },
  //   })
  //   .from('.kalendar-natjecanja inner', { y: innerHeight * 2 })
  // .from(".outsole", { y: innerHeight * 1.5 });

  // gsap.fromTo(
  //   '.headerOverlay',
  //   {
  //     scrollTrigger: {
  //       trigger: '.kalendar-natjecanja',
  //       markers: true,
  //       start: 'top top',
  //       end: '+=550',
  //       // pin: '.container',
  //       scrub: 1,
  //       toggleActions: 'play complete complete reverse',
  //     },
  //     backgroundColor: 'rgba(255,255,255,0.2)',
  //     backdropFilter: 'blur(20px)',
  //   },
  //   {
  //     scrollTrigger: {
  //       trigger: '.kalendar-natjecanja',
  //       markers: true,
  //       start: 'top top',
  //       end: '+=550',
  //       // pin: '.container',
  //       scrub: 1,
  //       toggleActions: 'play complete complete reverse',
  //     },
  //     backgroundColor: 'rgba(255,255,255,0.2)',
  //     backdropFilter: 'blur(0px)',
  //   }
  // )

  // ScrollTrigger.create({
  //   scroller: 'body',
  //   trigger: '.kalendar-natjecanja',
  //   pin: '.kalendar-natjecanja',
  //   start: 'top top+=150',
  //   end: '+=450',
  //   // duration: 2,
  //   // pinType: 'fixed', // transform fixed
  //   // pinSpacing: false,
  //   // markers: true,
  // })

  ScrollTrigger.create({
    scroller: 'body',
    trigger: '.main',
    pin: '.main',
    start: 'top top',
    end: '+=350',
    // pinSpacing: false,
    pinType: 'fixed', // transform fixed
    // markers: true,
  })

  // gsap.from('.main', {
  //   scrollTrigger: {
  //     trigger: '.main',
  //     pin: '.main',
  //     pinSpacing: false,
  //     pinType: 'fixed', // transform fixed
  //     markers: true,
  //     start: 'top top',
  //     endTrigger: '.kalendar-natjecanja',
  //     end: 'bottom top+=150px',
  //     markers: true,
  //     // duration: 0.5,
  //     toggleActions: 'play complete none none',
  //   },
  //   // opacity: 0.4,
  // })

  // let trigger = gsap.utils.toArray('.zoomin')

  // trigger.forEach((element) => {
  //   const tl = gsap.timeline({
  //     scrollTrigger: {
  //       trigger: element,
  //       start: 'top bottom-=100',
  //       pinSpacing: false,
  //       duration: 0.6,
  //     },
  //   })

  //   tl.from(element, {
  //     scale: 1.2,
  //     opacity: 0,
  //   })
  // })

  // let trigger2 = gsap.utils.toArray('.fadein')

  // trigger2.forEach((element) => {
  //   const tl2 = gsap.timeline({
  //     scrollTrigger: {
  //       trigger: element,
  //       start: 'top bottom-=200',
  //       // end: '+=100',
  //       pinSpacing: false,
  //       duration: 1,
  //       toggleActions: 'play complete restart complete',
  //       markers: true,
  //     },
  //   })

  //   tl2.from(element, {
  //     y: 50,
  //     opacity: 0,
  //   })
  // })
})
